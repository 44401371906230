<template>
  <el-row>
    <el-col
      :span="24"
      style="margin-top: 20px;"
    >
      <div
        class="grid-content bg-purple-dark"
        style="width: 100%; text-align: center; padding-top: 30px; padding-bottom: 30px;"
      >
        <p style="font-size: 24px; font-weight: bold;">
          WEB DEV TOOLS
        </p>
        <p style="font-size: 16px;">
          free online developer tools.
        </p>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col
      :span="24"
      style="padding-top: 20px; padding-bottom: 20px;"
    >
      <div style="text-align: center; font-size: 3em;">
        <p>{{ ip }}</p>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col
      :span="24"
      style="padding-top: 20px; padding-bottom: 20px;"
    >
      <div style="text-align: center; font-size: 1.2em;">
        <p>{{ ua }}</p>
      </div>
    </el-col>
  </el-row>
</template>

<script type="ts">
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent ({
  name: "Index",
  setup() {
    let myIp = ref("0.0.0.0");

    onMounted(() => {
      // Get my ip address.
      fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            console.log(data);
            myIp.value = data.ip
          })
          .catch(error => {
            console.log(error);
          });
    })


    return {
      ip: myIp,
      ua: navigator.userAgent,
      output: ref('0'),
      cards: [{
        name: 'Text Count',
        description: 'Text count tool, with text byte.',
        link: 'TextCount'
      },{
        name: 'Text Encode / Decode',
        description: 'Text encode / decode tool. ( URL, Base64, HTML Entity, Unicode Escape Sequence )',
        link: 'TextEncode'
      },{
        name: 'TextReplace',
        description: 'Text simple replace tool.',
        link: 'TextReplace'
      }]
    }
  },
  methods: {
    onClickResolveButton() {
      // dns.resolve('example.org').then((addresses) => {
      //   console.log(addresses);
      // });


      // const options = {
      //   family: 6,
      //   hints: dns.ADDRCONFIG | dns.V4MAPPED,
      // };
      //
      // dns.lookup('example.com', options, (err, address, family) =>
      //     console.log('address: %j family: IPv%s', address, family));
    }

  }
})
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
  height: 100%;
}
.el-card {
  background-color: #333333;
  border: 1px solid #333333;
}
.box-card {
  width: 540px;
  background: #333333;
}

</style>